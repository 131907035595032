import './BannerHtml.scss'

import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { useContext } from 'react'

import useSeoTelemetry from '../../../../hooks/useSeoTelemetry'
import SanitySchema from '../../../../lib/types/sanity-schema'

export type SlideBannerHtmlProps = {
  slide: SanitySchema.HeroItem
}

const BannerHtml: React.FC<SlideBannerHtmlProps> = ({ slide }) => {
  const { language } = useContext(I18nextContext)
  const { htmlBanner } = slide
  const { pushToGoogleTagManager } = useSeoTelemetry()

  if (slide?.heroItemDataLayer && slide.heroItemDataLayer?.dataLayer) {
    pushToGoogleTagManager({
      data: JSON.parse(slide.heroItemDataLayer.dataLayer),
    })
  }

  return (
    (htmlBanner && (
      <iframe
        src={`/${htmlBanner?.slug?.current}/index_${language}.html`}
        title="banner"
        key={htmlBanner?._id}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={false}
        className="htmlBanner slideItem"
        role="banner"
      />
    )) ||
    null
  )
}

export default BannerHtml
