import { Accordion as AccordionDS, AccordionItem, useAccordion } from '@lk/lk-design-system'
import { toPlainText } from '@portabletext/react'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import useIsLoaded from '../../hooks/useIsLoaded'
import useSeoSchemaUtils from '../../hooks/useSeoSchemaUtils/useSeoSchemaUtils'
import useSeoTelemetry, { GtmEvents } from '../../hooks/useSeoTelemetry'
import * as SanitySchema from '../../lib/types/sanity-schema'
import PortableText from '../PortableText/PortableText'

export type SanityBlockProps = Omit<SanitySchema.SanityBlock, '_type'>

export type AccordionProps = Omit<SanitySchema.Accordion, '_type'> & {
  content: AccordionItemProps
  itemsOpened: boolean
}

export type AccordionItemProps = Omit<SanitySchema.AccordionItem, '_type'> & {
  body?: Array<SanitySchema.SanityKeyed<SanityBlockProps>>
}

const Accordion: FC<AccordionProps> = ({ title, content, itemsOpened, isFAQ }) => {
  const { isLoaded } = useIsLoaded()
  const { pushToGoogleTagManager } = useSeoTelemetry()
  const { isOpen, toggleItem } = useAccordion(
    content.map((item) => ({
      open: item.openByDefault ? item.openByDefault : itemsOpened,
    })),
  )

  const faqSchema = {
    type: 'FAQPage',
    mainEntity: content.map((question) => ({
      '@type': 'Question',
      name: question.title,
      acceptedAnswer: { '@type': 'Answer', text: toPlainText(question.body) },
    })),
  }

  const handleOpenAccordionItem = (accordionItem: SanitySchema.AccordionItem, itemIndex: number) => {
    toggleItem(itemIndex)
    const isOpenItem = isOpen(itemIndex)
    if (!isOpenItem) {
      pushToGoogleTagManager({
        event: GtmEvents.INTERACTION_FAQS,
        data: {
          faqs_category: title,
          faqs_content: accordionItem.title,
        },
      })
    }
  }

  const structuredData = useSeoSchemaUtils([faqSchema])

  return (
    <>
      <AccordionDS>
        {content.map((item, i) => (
          <AccordionItem
            key={item._key}
            open={isLoaded ? isOpen(i) : true}
            title={item.title}
            onButtonClick={() => {
              handleOpenAccordionItem(item, i)
            }}
          >
            {item.body ? <PortableText blocks={item.body} /> : null}
          </AccordionItem>
        ))}
      </AccordionDS>
      {isFAQ &&
        structuredData.map((jsonLd, index) => (
          <Helmet key={index}>
            <script type="application/ld+json">{jsonLd}</script>
          </Helmet>
        ))}
    </>
  )
}

export default Accordion

export const AccordionBuilder = ({ title, content, isFAQ, itemsOpened = false }: AccordionProps) => (
  <Accordion title={title} content={content} itemsOpened={itemsOpened} isFAQ={isFAQ} />
)
