import './BannerVideo.scss'

import classNames from 'classnames'
import React, { ReactElement } from 'react'
import ReactPlayer from 'react-player'

import useSeoTelemetry from '../../../../hooks/useSeoTelemetry'
import { DataLayer } from '../../../../lib/types/sanity-schema'

export interface BannerVideoProps {
  title: string
  body: ReactElement
  footer: ReactElement
  cta: ReactElement<HTMLButtonElement>
  videoUrl: string
  onVideoEnded?: () => void
  heroItemDataLayer?: DataLayer
}

const BannerVideo: React.FC<BannerVideoProps> = ({
  title,
  body,
  footer,
  cta,
  videoUrl,
  onVideoEnded,
  heroItemDataLayer,
}) => {
  const { pushToGoogleTagManager } = useSeoTelemetry()

  if (heroItemDataLayer && heroItemDataLayer?.dataLayer) {
    pushToGoogleTagManager({
      data: JSON.parse(heroItemDataLayer.dataLayer),
    })
  }

  return (
    <div className="bannerVideo">
      <figure className={classNames('slide-video')}>
        <ReactPlayer
          url={videoUrl}
          muted
          playing
          controls
          onEnded={onVideoEnded}
          width="100%"
          height="100%"
          aria-hidden="true"
          loop={false}
        />
      </figure>
      <span className="slide-description">
        {title && <p className="lk-hero__heading">{title}</p>}
        {body && <div className="lk-hero__body">{body}</div>}
        {cta && <div className="lk-hero__actions">{cta}</div>}
        {footer && <div className="lk-hero__footer">{footer}</div>}
      </span>
    </div>
  )
}

BannerVideo.defaultProps = {
  onVideoEnded: () => null,
}

export default BannerVideo

BannerVideo.defaultProps = {
  heroItemDataLayer: undefined,
}
