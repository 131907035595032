import '../Widgets.scss'
import './LoanSimulator.scss'

import { Button, Icon, Input, Popover, Radio } from '@lk/lk-design-system'
import classNames from 'classnames'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React, { FC, FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../hooks/useI18nUtils'
import useSeoTelemetry, { GtmEvents } from '../../../../hooks/useSeoTelemetry'
import SanitySchema from '../../../../lib/types/sanity-schema'
import LKApis from '../../../../services/lk-apis/LKApisProvider'
import { LoanSimulatorResult } from '../../../../services/lk-apis/types'
import Figure from '../../../Figure/Figure'
import PortableText from '../../../PortableText/PortableText'
import BolApiErrorMessage from '../../common/BolApiErrorMessage'
import loanSimulatorEvents from './constants/loanSimulatorEvents'

interface LoanSimulatorProps {
  simulator:
    | SanitySchema.SanityReference<SanitySchema.LoanSimulator>
    | SanitySchema.SanityDocument<SanitySchema.LoanSimulator>
}

type LoanValues = {
  goalId: string
  amount: string
  monthToPay: string
  yearToPay: number
}

type GoalInputBoundary = {
  min: number
  max: number
}

type SimulatorEventParams = {
  funnelName: string
  funnelStep: string
  fee?: string
  gtmEvent: GtmEvents
}

const LoanSimulator: FC<LoanSimulatorProps> = ({ simulator }) => {
  const { t } = useTranslation()
  const { lkWebApi } = useContext(LKApis)
  const breakpoints = useBreakpoint()
  const { formatCurrency, formatInterest } = useI18nUtils()
  const { pushToGoogleTagManager } = useSeoTelemetry()

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _id,
    title,
    description,
    loanSimulatorGoal,
    calculatorMessage,
    legalText,
    illustration,
    loanSimulatorDataLayer,
  } = simulator

  const [amountPopover, setAmountPopover] = useState(false)
  const [yearPopover, setYearPopover] = useState(false)
  const [response, setResponse] = useState<LoanSimulatorResult>()
  const [errorCode, setErrorCode] = useState<string>(null)
  const [disabledButton, setDisabledButton] = useState(true)
  const [buttonBehaviour, setButtonBehaviour] = useState({
    calculateButton: 'primary',
    applyButton: 'secondary',
  })
  const [values, setValues] = useState<LoanValues>({
    goalId: '',
    amount: '',
    monthToPay: '',
    yearToPay: 0,
  })
  const [goal, setGoal] = useState<SanitySchema.SanityKeyedReference<SanitySchema.LoanSimulatorGoal>>({
    maximumAmount: null,
    minimumAmount: null,
    maximumYearTerm: null,
    minimumYearTerm: null,
    termType: null,
    monthTerm: null,
    validations: null,
  })
  const [goalAmountBoundary, setGoalAmountBoundary] = useState<GoalInputBoundary>({
    min: goal.minimumAmount,
    max: goal.maximumAmount,
  })
  const [goalYearsBoundary, setGoalYearsBoundary] = useState<GoalInputBoundary>({
    min: goal.minimumYearTerm,
    max: goal.maximumYearTerm,
  })
  const [visibleLegalText, setVisibleLegalText] = useState([])
  const applyButtonClassnames = classNames('lk-button lk-calculator__button lk-calculator__button--apply', {
    disabled: disabledButton,
    'lk-button--primary': buttonBehaviour.applyButton === 'primary',
    'lk-button--secondary': buttonBehaviour.applyButton === 'secondary',
  })

  const setDefaultBoundaries = (currentGoal: SanitySchema.LoanSimulatorGoal) => {
    setGoalYearsBoundary({
      min: currentGoal.minimumYearTerm,
      max: currentGoal.maximumYearTerm,
    })
    setGoalAmountBoundary({ min: currentGoal.minimumAmount, max: currentGoal.maximumAmount })
  }

  const buildSimulatorEvent = ({ funnelName, funnelStep, fee }: SimulatorEventParams) => ({
    process_name: values.goalId,
    funnel_name: funnelName,
    funnel_step: funnelStep,
    ...(loanSimulatorGoal.length > 1
      ? { objetivo_prestamo: loanSimulatorGoal?.find((currentGoal) => currentGoal.goalId === values.goalId)?.title }
      : {}),
    importe_solicitado: values.amount,
    plazo_prestamo: values.yearToPay || `${values.monthToPay}_meses`,
    ...(fee ? { cuota_prestamo: fee } : {}),
    ...(loanSimulatorDataLayer?.dataLayer ? JSON.parse(loanSimulatorDataLayer.dataLayer) : {}),
  })

  const sendSimulatorEvent = (params: SimulatorEventParams) => {
    const eventData = buildSimulatorEvent(params)
    pushToGoogleTagManager({ event: params.gtmEvent, data: eventData, sendUserData: loanSimulatorDataLayer?.userData })
  }

  const sendSimulatorErrorEvent = (errorMessage: string) => {
    const eventData = {
      process_name: values.goalId,
      simulator_error: errorMessage,
      ...(loanSimulatorDataLayer?.dataLayer ? JSON.parse(loanSimulatorDataLayer.dataLayer) : {}),
    }
    pushToGoogleTagManager({
      event: GtmEvents.SIMULATOR_ERROR_MESSAGE,
      data: eventData,
      sendUserData: loanSimulatorDataLayer?.userData,
    })
  }

  const handleChange = useCallback((e) => {
    e.persist()
    const { name, value } = e.target
    const newValues: Partial<LoanValues> = {}
    newValues[name] = value
    if (name === 'goalId') {
      newValues.monthToPay = null
      newValues.yearToPay = null
    }
    setValues((prevState) => ({
      ...prevState,
      ...newValues,
    }))
  }, [])

  const isSimulatorValid = () => {
    const inputsIds = [`${_id}-yearToPay`, `${_id}-amount`]

    const isInvalid = inputsIds.some((id) => {
      const currentElement = document.getElementById(id) as HTMLInputElement
      if (currentElement && !currentElement.checkValidity()) {
        sendSimulatorErrorEvent(currentElement.validationMessage)
        currentElement.reportValidity()
        return true
      }
      return false
    })

    return !isInvalid
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    sendSimulatorEvent({
      funnelName: loanSimulatorEvents.step_01.funnelName,
      funnelStep: loanSimulatorEvents.step_01.funnelStep,
      gtmEvent: GtmEvents.SIMULATOR_PROCESS,
    })

    e.preventDefault()
    if (!isSimulatorValid()) return
    setResponse(null)
    const apiTypes = {
      month: 'Monthly',
      year: 'Annual',
    }

    try {
      const formResponse = await lkWebApi.postLoanSimulator({
        goalId: values.goalId,
        amount: {
          amount: parseInt(values.amount, 10),
          currency: 'EUR',
        },
        monthToPay: values.yearToPay * 12 || parseInt(values.monthToPay, 10),
        type: apiTypes[goal.termType],
      })
      setResponse(formResponse.data.data)
      setDisabledButton(false)
      setButtonBehaviour({
        calculateButton: 'secondary',
        applyButton: 'primary',
      })
      setErrorCode(null)
      sendSimulatorEvent({
        funnelName: loanSimulatorEvents.step_02.funnelName,
        funnelStep: loanSimulatorEvents.step_02.funnelStep,
        fee: `${formResponse.data.data.fee}`,
        gtmEvent: GtmEvents.SIMULATOR_COMPLETED,
      })
    } catch (error) {
      setDisabledButton(true)
      const receivedErrorCode = error?.response?.data?.errors?.detail
      setErrorCode(receivedErrorCode || 'UNKNOWN_ERROR')
      sendSimulatorErrorEvent(t(`bolApiErrors.${receivedErrorCode}.message`, t('bolApiErrors.default.message')))
    }
  }

  const checkValidationRules = (e) => {
    if (!goal.validations || goal.validations.length === 0) return

    const { value } = e.target
    const matchingRule = goal.validations.find((rule) => value >= rule.minAmount && value <= rule.maxAmount)
    if (matchingRule) {
      setGoalYearsBoundary({
        min: matchingRule.minTerm,
        max: matchingRule.maxTerm,
      })
      setGoalAmountBoundary({ min: matchingRule.minAmount, max: matchingRule.maxAmount })
    } else {
      setDefaultBoundaries(goal)
    }
  }

  const handleContractButton = () => {
    sendSimulatorEvent({
      funnelName: loanSimulatorEvents.step_03.funnelName,
      funnelStep: loanSimulatorEvents.step_03.funnelStep,
      fee: `${response.fee}`,
      gtmEvent: GtmEvents.SIMULATOR_COMPLETED_CTA_CONTRATAR,
    })
  }

  const isCalculateDisabled = () => {
    const { goalId, amount, monthToPay, yearToPay } = values
    if (!goalId || !amount) return true
    if ((goal.termType === 'month' && !monthToPay) || (goal.termType === 'year' && !yearToPay)) return true
    return false
  }

  useEffect(() => {
    if (loanSimulatorGoal && loanSimulatorGoal.length === 1) {
      const firstGoal = loanSimulatorGoal[0]
      setValues({
        goalId: firstGoal.goalId,
        amount: '',
        monthToPay: '',
        yearToPay: 0,
      })
      setGoal(firstGoal)
      setDefaultBoundaries(firstGoal)
    }
  }, [loanSimulatorGoal])

  useEffect(() => {
    const legal = []
    if (legalText && legalText.length > 0) {
      legal.push(legalText)
    }
    if (goal?.legalText && goal.legalText.length > 0) {
      legal.push(goal.legalText)
    }
    setVisibleLegalText(legal)
  }, [goal, legalText])

  return (
    <>
      <BolApiErrorMessage errorCode={errorCode} onClose={() => setErrorCode(null)} />
      <form
        onSubmit={handleSubmit}
        className="lk-calculator lk-widget-loan-simulator"
        aria-label="form"
        data-testid="lk-widget-loan-simulator"
        noValidate
      >
        <div className="lk-calculator__container row">
          <div className="lk-calculator__main col-lg-8 col-md-12 p-0">
            {illustration ? <Figure node={illustration.image} className="lk-calculator__image" /> : null}
            <div className="lk-calculator__content">
              <div className="lk-calculator__intro">
                <h2 className="lk-calculator__title">{title}</h2>
                {description && <PortableText blocks={description} />}
              </div>
              <p className="lk-calculator__highlighted-text strong">
                {t('widgetLoanSimulator.setData')}
                <sup>*</sup>
              </p>
              <div className="lk-calculator__options">
                {loanSimulatorGoal?.length > 1 && (
                  <div className="row lk-widget-loan-simulator-goals">
                    <div className="col-sm-3 col-xs-12">
                      <p className="strong lk-calculator__options-desc">{t('widgetLoanSimulator.moneyDestination')}</p>
                    </div>
                    <div className="lk-calculator__options-radio col-sm-9 col-xs-12">
                      {loanSimulatorGoal?.map((item) => (
                        <div key={item._id}>
                          <Radio
                            label={item.title}
                            name="goalId"
                            value={item.goalId}
                            onChange={(ev) => {
                              setGoal(item)
                              setDefaultBoundaries(item)
                              handleChange(ev)
                            }}
                            checked={values.goalId === item.goalId}
                            required
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-sm-3 col-xs-12 as-center">
                    <p className="lk-calculator__options-desc strong">
                      <label htmlFor={`${_id}-amount`}>{t('widgetLoanSimulator.amount')}</label>
                      <Icon
                        name="question-o"
                        className="as-center"
                        onTouchStart={() => setAmountPopover(true)}
                        onMouseOver={() => setAmountPopover(true)}
                        onTouchEnd={() => setAmountPopover(false)}
                        onMouseLeave={() => setAmountPopover(false)}
                      />
                    </p>
                  </div>
                  <div className="col-sm-9 col-xs-12 d-flex">
                    {amountPopover === true ? <Popover body={t('widgetLoanSimulator.amountPopover')} /> : null}
                    <Input
                      append="€"
                      id={`${_id}-amount`}
                      name="amount"
                      type="number"
                      onInput={(e) => {
                        checkValidationRules(e)
                        handleChange(e)
                      }}
                      required
                      max={goalAmountBoundary.max}
                      min={goalAmountBoundary.min}
                      alt={t('widgetLoanSimulator.amount')}
                      value={values.amount || 0}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                {goal.termType === 'month' ? (
                  <div className="row">
                    <div className="col-sm-3 col-xs-12">
                      <p className="lk-calculator__options-desc strong">{t('widgetLoanSimulator.presetTerms')}</p>
                    </div>
                    <div className="lk-calculator__options-radio col-sm-9 col-xs-12">
                      {goal.monthTerm?.map((item) => (
                        <div key={`${item}-months`}>
                          <Radio
                            name="monthToPay"
                            id={`${_id}-monthToPay`}
                            label={`${item} ${t('widgetLoanSimulator.months')}`}
                            value={item}
                            onChange={handleChange}
                            checked={values.monthToPay === item.toString()}
                            required
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {goal.termType === 'year' ? (
                  <div className="row">
                    <div className="col-sm-3 col-xs-12 as-center">
                      <p className="lk-calculator__options-desc strong">
                        {t('widgetLoanSimulator.term')}
                        <Icon
                          name="question-o"
                          className="as-center"
                          onTouchStart={() => setYearPopover(true)}
                          onMouseOver={() => setYearPopover(true)}
                          onTouchEnd={() => setYearPopover(false)}
                          onMouseLeave={() => setYearPopover(false)}
                        />
                      </p>
                    </div>
                    <div className="col-sm-9 col-xs-12">
                      <div className="d-flex">
                        <Input
                          append={t('widgetLoanSimulator.years')}
                          name="yearToPay"
                          id={`${_id}-yearToPay`}
                          aria-label={t('widgetLoanSimulator.years')}
                          type="number"
                          onInput={handleChange}
                          required
                          max={goalYearsBoundary.max}
                          min={goalYearsBoundary.min}
                          value={values.yearToPay || 0}
                          onFocus={(e) => e.target.select()}
                        />
                        {yearPopover === true ? <Popover body={t('widgetLoanSimulator.yearPopover')} /> : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-sm-3 col-xs-12" />
                  <div className="col-sm-9 col-xs-12">
                    <Button
                      type="submit"
                      className="lk-widget-loan-simulator-button"
                      buttonRole={buttonBehaviour.calculateButton as 'primary' | 'secondary'}
                      label={t('widgetLoanSimulator.calculate')}
                      role="button"
                      disabled={isCalculateDisabled()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lk-calculator__aside col-lg-4 col-md-12 bg-berenjena-claro-4 p-0" data-testid="loan-proposal">
            <div className="lk-calculator__content">
              <p className="lk-calculator__highlighted-text strong m-0">{t('widgetLoanSimulator.quota')}</p>
              <div className="lk-calculator__button-block">
                <div className="lk-calculator__result">
                  <p className="lk-calculator__quantity">
                    <span className="strong">{formatCurrency(response?.fee)}</span>
                    {t('widgetLoanSimulator.currencyPerMonth')}
                  </p>
                </div>
                {breakpoints.sm && (
                  <a
                    href={response ? response.url : '#'}
                    className={applyButtonClassnames}
                    onClick={handleContractButton}
                  >
                    {t('widgetLoanSimulator.personalizedResearch')}
                  </a>
                )}
              </div>
              <p className="m-0">
                TIN: {formatInterest(response?.tin)}
                <span className="strong text-large"> TAE: {formatInterest(response?.tae)}</span>
              </p>
              <div className="lk-calculator__key-points">
                <div className="lk-calculator__key-point">
                  <p>{t('widgetLoanSimulator.financedAmount')}</p>
                  <p className="strong">{formatCurrency(response?.amount?.amount)}</p>
                </div>
                {response?.openingCommissionAmount?.amount > 0 && (
                  <div className="lk-calculator__key-point">
                    <p>
                      {t('widgetLoanSimulator.openingCommission')}{' '}
                      <span className="strong">({formatInterest(response?.openingCommission)})</span>
                    </p>
                    <p className="strong">{formatCurrency(response?.openingCommissionAmount?.amount)}</p>
                  </div>
                )}
                {response?.managementCommissionAmount?.amount > 0 && (
                  <div className="lk-calculator__key-point">
                    <p>{t('widgetLoanSimulator.managementCommission')}</p>
                    <p className="strong">{formatCurrency(response?.managementCommissionAmount?.amount)}</p>
                  </div>
                )}
                <div className="lk-calculator__key-point">
                  <p>{t('widgetLoanSimulator.bankInterest')}</p>
                  <p className="strong">{formatCurrency(response?.interestAmount?.amount)}</p>
                </div>
                <div className="lk-calculator__key-point">
                  <p>{t('widgetLoanSimulator.totalLoan')}</p>
                  <p className="strong">{formatCurrency(response?.totalAmount?.amount)} </p>
                </div>
                {response?.totalWithOpeningCommissionAmount?.amount > 0 && (
                  <div className="lk-calculator__key-point">
                    <p>{t('widgetLoanSimulator.totalLoanWithOpeningCommission')}</p>
                    <p className="strong">{formatCurrency(response?.totalWithOpeningCommissionAmount?.amount)} </p>
                  </div>
                )}
                <div
                  className="lk-calculator__key-point legal" // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t('widgetLoanSimulator.amortizationSystem'),
                  }}
                />
              </div>
              {calculatorMessage && (
                <div className="lk-calculator__message">
                  <PortableText blocks={calculatorMessage} />
                </div>
              )}
              {!breakpoints.sm && (
                <a
                  href={response ? response.url : '#'}
                  className={applyButtonClassnames}
                  onClick={handleContractButton}
                >
                  {t('widgetLoanSimulator.personalizedResearch')}
                </a>
              )}
            </div>
          </div>
        </div>
        {visibleLegalText && visibleLegalText.length > 0 && (
          <div className="lk-calculator__footer">
            <div className="row">
              {visibleLegalText?.map((legalBlock, index) => (
                <div className="col-lg-12" key={`legal-${legalBlock._key ? legalBlock._key : index}`}>
                  <PortableText blocks={legalBlock} />
                </div>
              ))}
            </div>
          </div>
        )}
      </form>
    </>
  )
}

export default LoanSimulator
